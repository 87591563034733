
.events-button{
    border-radius: 5px;
    background-image: var(--bg-image);
    box-shadow: 0px 0px 18px 0px rgb(255, 255, 255);
    width: 200px;
    
    height: 50px;
    /* cursor: pointer; */
   cursor: grab;
    color: var(--color-white);
    
    border:0px;
    font-size: medium;
    font-weight: 600;
    font-family: var(--font-family-montserrat);
    border:0px;
    text-align: center;
    margin: 2rem 0;
}
.events-button:hover{
    text-shadow: 0px 0px 10px var(--glow-2);
}
.events-button:active{
  box-shadow: 2px 2px 6px var(--glow), -2px -2px 6px var(--glow);
  transform: scale(0.events-97);
  transition: 0.events-25s;
}

.register_open_date_time{
  font-size: 18px;
  font-weight: 800;
  text-align: center;
}

.events-main{
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  height: 100vh;
  justify-content: center;
  padding-bottom: 3rem;
  z-index: -10;
  
  
  
}
.events-core{
  display: flex;
  flex-wrap: wrap;
  
  flex-direction: column;
  justify-content:center;
  gap: 15px;
  
  
  
}
.events-cards-row{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
  gap: 3rem;  

  
  
  
}
.events-heading-text{
  font-family: var(--font-family-montserrat);
  font-weight: 600;
  font-size: 4rem;
  
  padding: 1rem 0rem;
  text-align: center;
}


.events-button{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 15px;
}

.events-vertical-card{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 400px;
  height: 550px;
  backdrop-filter: blur(15px);
  box-shadow: 0px 0px 10px 0px rgb(255, 255, 255);
  border-radius: 15px;
  padding: 0rem 1rem;
  transition: 0.5s;
}
.events-vertical-card:hover{
  box-shadow: 0px 0px 30px 0px rgb(255, 255, 255);
  width: 410px;
  height: 580px;
}
.events-vertical-card-userimage{
  width: 250px;
  height: 250px;
  border-radius: 50%;
}
.events-vertical-card-top{
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.events-vertical-card-bottom{
  display: flex;
  flex-direction: column;
  
  justify-content: center;
  align-items: center;
  text-align: center;
}
.events-vertical-name-text{
  font-size: xxx-large;
  font-family: var(--font-family-montserrat);
  font-weight: 600;
  text-align: center;
  color: var(--color-text);
  text-transform: uppercase;
}
.events-vertical-info-text{
  font-size: medium;
  font-weight: 600;
}

@media (max-width: 768px) {
  

  .events-vertical-card {
    width: auto;
    height: auto;
  }

  .events-vertical-card-userimage {
    width: 150px;
    height: 150px;
  }

  .events-vertical-card-top {
    margin-bottom: 1rem;
  }
}



@media (max-width: 768px) {
  .events-main {
    padding: 1rem;
  }

  .events-core {
    gap: 10px; 
  }

  .events-cards-row {
    gap: 15px; 
  }

  .events-heading-text {
    font-size: 1.5rem; 
  }

  .events-vertical-card {
    width: calc(50% - 10px); 
    height: auto;
    padding: 0.5rem;
  }

  .events-vertical-card-userimage {
    width: 100px;
    height: 100px;
  }

  .events-vertical-card-bottom {
    margin-bottom: 1rem;
  }

  .events-vertical-name-text {
    font-size: large; 
    
  }

  .events-vertical-info-text {
    font-size: small; 

  }

  .events-button {
    font-size: small; 
    width: 100px; 
    height: 40px; 
    margin: 1rem 0; 
  }
}

@media (max-width: 480px) {
  .events-vertical-card {
    width: 100%; 
  }

  .events-vertical-card-userimage {
    width: 80px;
    height: 80px;
  }
}
