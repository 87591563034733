

.footer{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    /* align-items: center; */
    margin-top:4rem ;
    flex-wrap: wrap;
    padding-top: 4rem;
    background-color: rgba(red, green, blue,) ;
    padding: 20px;
    /* position: relative; */
    padding: 20px;
    padding-left: 100px;
    padding-right: 100px;
backdrop-filter: blur(6px);

/* mix-blend-mode: color-dodge; */
mix-blend-mode:luminosity;
width: auto;
}
.footer-head-mirage,.footer-head-large,.footer-para{
    text-align: center;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.footer-head-large{
    font-size: 3rem;
    letter-spacing: 2px;
    word-spacing: 2px;
}
.footer-para{
    font-size: 1rem;
}
.footer-head-mirage{
    font-size: 1.5rem;
    font-weight: 900;
}
.footer-contact-btn{
display: flex;
justify-content: center;
align-items: center;
margin:auto;
height: 2rem;
width: 7rem;
/* font-weight: bolder; */
margin-top: 2rem;
flex-wrap: wrap;
/* font-family: Verdana, Geneva, Tahoma, sans-serif; */
font-weight: 1000;

/* ///// */
margin-bottom: 2rem;
margin-top: 2rem;
}
.footer-navbar{
    display: flex;
    flex-direction: row;
    gap: 2vw;
    list-style: none;
    text-decoration: none;
    margin: 0;
    padding: 0;
    margin-top: 2rem;
    /* padding-left: -20px; */
}
.navbar-navbar-main{
    display: flex;
    justify-content: space-between;
padding: 0;
margin: 0;
}

.footer-copyright{
    margin-top: 4rem;
}

.right-footer-wing{
    margin-top: 1.8rem;
}
.footer-para-2{
    word-spacing: 4px;
}
.emailbtn{
    backdrop-filter: blur(5px);
    background-color:rgba(0, 0, 0, 0.8) ;
    border: 1px solid black;  
    width:16vw; 
height: 4vh;
padding: 10px;
text-align: center;
/* margin-left: 40px; */
}
/* Media queries for responsiveness */
@media screen and (max-width: 518px) {
   
    .footer-para-2{
margin-left: 23vw;
font-size: 16px;
flex-wrap: wrap;
flex-basis: auto;

    }
    .footer-head-large{
        font-size: 22px;
        letter-spacing: 1px;
        word-spacing: 2px;
    }
    .footer-para{
        font-size: 10px;
        /* width: 16rem; */
        letter-spacing: 0px;
        word-spacing: 0px;
        flex-wrap: wrap;
    }
    .footer-head-mirage{
        font-size: 20px;
        font-weight: 900;
    }
    .footer-navbar{
        flex-direction: column;
    }
    .footer-copyright{
        margin-top: 3rem;
    }
    
    .right-footer-wing{
        margin-top: 1.8rem;
    }
    .footer-para-2{
        word-spacing: 4px;
    }
    .footer-copyright-para{
        font-size: 12px;
        /* margin: 0; */
        text-align: center;
        
    }
    .footer{
        padding-left: 20px;
    padding-right: 20px;
    margin: 0;
    }
    .footer-nav-anker{
        font-size: 15px;
    }
    .emailbtn{
        backdrop-filter: blur(5px);
        background-color:rgba(0, 0, 0, 0.8) ;
        border: 1px solid black;  
        width:10rem; 
    height: 4vh;
    padding: 10px;
    text-align: center;
    margin-left: 80px;
    margin-top: 20px;
    /* margin-right: 50px; */
    }
}

@media screen and (min-width:519px) and (max-width:1300px) {
    .footer {
        padding-left: 20px;
        padding-right: 20px;
    }
.footer-navbar{
    flex-direction: column;
}
.emailbtn{
    backdrop-filter: blur(5px);
    background-color:rgba(0, 0, 0, 0.8) ;
    border: 1px solid black;  
    width:16rem; 
height: 4vh;
padding: 10px;
text-align: center;
margin-left: 120px;
margin-top: 20px;
/* margin-right: 50px; */
}
.footer-para-2{
    margin-left: 23vw;
    font-size: 16px;
    flex-wrap: wrap;
    flex-basis: auto;
    
        }
        .footer-head-large{
            font-size: 35px;
            letter-spacing: 2px;
            word-spacing: 2px;
        }
        .footer-para{
            font-size: 13px;
            /* width: 16rem; */
            letter-spacing: 0px;
            word-spacing: 0px;
        }
        .footer-head-mirage{
            font-size: 20px;
            font-weight: 900;
        }
        .footer-navbar{
            flex-direction: column;
        }
        .footer-copyright{
            display:flex;
            justify-content: center;
            align-items: center;
            margin-top: 3rem;
            text-align: center;
            /* margin: auto; */
        }
        
        .right-footer-wing{
            margin-top: 1.8rem;
        }
        .footer-para-2{
            word-spacing: 4px;
        }
        .footer-copyright-para{
            font-size: 12px;
            /* margin: 0; */
            text-align: center;
            
        }
        .footer{
            padding-left: 20px;
        padding-right: 20px;
        margin: 0;
        }
        .footer-nav-anker{
            font-size: 15px;
        }
    
}


