.qrscan-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 85vh;
}

.qrscan-h1 .qrscan-p{
    margin: 0;
    padding: 0;
}

.qrscan-core{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}
.qrscan-scanner-container{
    width: 500px;
    height: 500px;
    border-radius: 15px;
    /* height: 800px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: red; */
    border: 5px solid white;
    overflow: hidden;
}

.qrscan-input-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.qrscan-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 3rem;
    padding: 2rem;
    border-radius: 15px;
    background-color: transparent;
    backdrop-filter: blur(15px);
    
    box-shadow: 0px 0px 20px 0px rgb(255, 255, 255);
}