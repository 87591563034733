

.signin-main {
  display: flex;
  padding-bottom: 3rem;
  padding-top: 1rem;
  height: 100vh;
  width: 100vw;

  justify-content: center;
  align-items: center;

}


.signin-main-core {
  position: relative;

  width: fit-content;
  height: fit-content;
  /* width: 80%; */
  /* height: 90%; */
  background-color:transparent;
  backdrop-filter: blur(10px);
  box-shadow: 0px 0px 20px 0px rgb(255, 255, 255);
  border-radius: 12px;
  overflow: hidden;
}

@media (max-width: 1200px) {
  .signin-main-core {
    transform: scale(0.7);
  }
}

@media (max-width: 1000px) {
  .signin-main-core {
    transform: scale(0.6);
  }
}

@media (max-width: 800px) {
  .signin-main-core {
    transform: scale(0.5);
  }
}

@media (max-width: 600px) {
  .signin-main-core {
    transform: scale(0.4);
  }
}

@media (max-width: 440px) {
  .signin-main-core {
    transform: scale(0.3);
  }
}

@media (max-width: 350px) {
  .signin-main-core {
    transform: scale(0.28);
  }
}

@media (max-width: 330px) {
  .signin-main-core {
    transform: scale(0.25);
  }
}

@media (max-width: 300px) {
  .signin-main-core {
    transform: scale(0.20);
  }
}


.signin-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 500px;
  padding: 2rem 2rem;
  background-color:transparent;
  backdrop-filter: blur(10px);
  transition: 1.25s;
}
.signin-form {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  width: 100%;
  /* gap: 1rem; */
  height: 100%;
  
  transition: 1s;
  /* background-color: yellow; */

}
.signin-form-input-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
h2{
  margin: 0px;
  padding: 0px;
}
p{
  margin: 0px;
  padding: 0px;
}
.signin-form-checkbox-container{
  display: flex;
  align-items: center;
  
}
.signin-form-checkbox-core{
  display: flex;
  gap: 50px;
  
}
.signin-form-checkbox{
  width: 15px;

}
.signin-form-checkbox-text{
  font-size: 15px;
  padding: 0px 5px;
  text-align: center;
  align-self: center;
}


.signin-form__icon {
  object-fit: contain;
  width: 30px;
  margin: 0 5px;
  opacity: 1;
  transition: 0.15s;

}

.signin-form__icon:hover {
  opacity: 0.5;
  transition: 0.15s;
  cursor: pointer;
}

.signin-form__input {
  width: 350px;
  height: 40px;
  margin: 4px 0;
  padding-left: 25px;
  font-size: 18px;
  letter-spacing: 0.15px;
  border: none;
  outline: none;
  font-family: var(--font-family-montserrat);
  background-color:transparent;
  backdrop-filter: blur(10px);
  transition: 0.25s ease;
  color: var(--color-white);
  border-radius: 8px;
  box-shadow: 0px 0px 5px 1px rgb(255, 255, 255);
  transition: 1s;
}

.signin-form__input:focus {
  box-shadow: inset 4px 4px 4px var(--glow-2), inset -4px -4px 4px var(--glow-2);
  transition: 1s;
}
.signin-form__input:hover {
  box-shadow: inset 4px 4px 4px var(--glow-2), inset -4px -4px 4px var(--glow-2);
  transition: 1s;
}

.signin-form__span {
  text-decoration: underline;
  margin-top: 10px;
  margin-bottom: 0px;
  font-family: var(--font-family-montserrat);
  color: var(--color-white);
}
.signin-form__span1 {
  text-decoration: underline;
  margin-top: 0px;
  margin-bottom: 10px;
  font-family: var(--font-family-montserrat);
  color: var(--color-white);
}

.signin-form__link {
  color: var(--color-white);
  font-size: 15px;
  font-family: var(--font-family-montserrat);
  margin-top: 25px;
  border-bottom: 1px solid var(--glow-2);
  cursor: pointer;
}
.signin-form__login{
  color: var(--color-white);
  font-size: 15px;
  font-family: var(--font-family-montserrat);
  margin-top: 15px;
  margin-bottom: 5px;
  cursor: pointer;
}
.offscreen{
  display: none;
}
.errmsg{
  color: var(--color-white);
  font-size: 12px;
  font-family: var(--font-family-montserrat);
  text-align: center;
  max-width: 400px;
  
}
.signin-form__link:hover {


  text-shadow: 0px 0px 15px var(--glow-2);

}



.signin-title {
  font-size: 34px;
  font-weight: 700;
  font-family: var(--font-family-montserrat);
  color: var(--color-white);
}

.signin-description {
  font-size: 14px;
  font-family: var(--font-family-montserrat);
  letter-spacing: 0.25px;
  text-align: center;
}


.signin-a-container {
  z-index: 100;
  left: calc(100% - 600px);
}

.signin-b-container {
  left: calc(100% - 600px);
  z-index: 0;
}

.signin-switch {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 400px;
  padding: 50px;
  z-index: 200;
  transition: 1.25s;
  background-color:transparent;
  backdrop-filter: blur(10px);
  overflow: hidden;
  box-shadow:0px 0px 20px 0px rgb(255, 255, 255);
  
}

.signin-switch__circle {
  position: absolute;
  width: 500px;
  height: 500px;
  border-radius: 50%;
  background-color: var(--button);
  box-shadow: inset 8px 8px 12px var(--glow-2), inset -8px -8px 12px var(--glow-2);
  bottom: -60%;
  left: -45%;
  z-index: -1;
  transition: 1.25s;
}

.signin-switch__circle--t {
  top: -30%;
  left: 60%;
  z-index: -1;
  width: 300px;
  height: 300px;
}

.signin-switch__container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  width: 400px;
  padding: 50px 55px;
  transition: 1.25s;
}

.signin-switch__button {
  cursor: pointer;
  box-shadow:0px 0px 20px 0px rgb(255, 255, 255);
}

.signin-switch__button:hover {
  box-shadow: 6px 6px 10px var(--glow), -6px -6px 10px var(--glow);
  transform: scale(0.985);
  transition: 0.25s;
}

.signin-switch__button:active,
.switch__button:focus {
  box-shadow: 2px 2px 6px var(--glow), -2px -2px 6px var(--glow);
  transform: scale(0.97);
  transition: 0.25s;
}

.signin-switch__description {
  color: var(--color-white);
}

.signin-button {
  width: 180px;
  height: 50px;
  border-radius: 25px;
  margin-top: 30px;
  font-weight: 700;
  font-size: 18px;
  font-family: var(--font-family-montserrat);
  letter-spacing: 1.15px;
  background-image: url(../../assets/BGs/BG\ Plain.png);
  color: var(--color-white);
  box-shadow:0px 0px 20px 0px rgb(255, 255, 255);
  border: none;
  outline: none;
}

.signin-button:hover {
  box-shadow: 6px 6px 10px var(--glow-2), -6px -6px 10px var(--glow-2);
  transform: scale(0.985);
  transition: 0.25s;
}

.signin-button:active,
.signin-button:focus {
  box-shadow: 2px 2px 6px var(--glow-2), -2px -2px 6px var(--glow-2);
  transform: scale(0.97);
  transition: 0.25s;
}

.persist,.cbx2 {
  position: relative;
  top: 1px;
  width: 15px;
  height: 15px;
  border: 1px solid var(--color-border);
  border-radius: 3px;
  transition: background 0.1s ease;
  cursor: pointer;
  display: block;
}
 
.persist:after,.cbx2:after {
  content: '';
  position: absolute;
  top: 1px;
  left: 5px;
  width: 2px;
  height: 8px;
  opacity: 0;
  transform: rotate(45deg) scale(0);
  border-right: 2px solid var(--color-white);
  border-bottom: 2px solid var(--color-white);
  transition: all 0.3s ease;
  transition-delay: 0.15s;
}

 
.lbl {
  margin-left: 5px;
  vertical-align: middle;
  cursor: pointer;
}
 
#persist:checked ~ .persist ,#cbx2:checked ~ .cbx2{
  border-color: transparent;
  background: var(--button);
  animation: jelly 0.6s ease;
}
 
#persist:checked ~ .persist:after,#cbx2:checked ~ .cbx2:after {
opacity: 1;
transform: rotate(45deg) scale(1);
}

 


.cntr,.cntr2 {
position: relative;
}

@keyframes jelly {
from {
  transform: scale(1, 1);
}

30% {
  transform: scale(1.25, 0.75);
}

40% {
  transform: scale(0.75, 1.25);
}

50% {
  transform: scale(1.15, 0.85);
}

65% {
  transform: scale(0.95, 1.05);
}

75% {
  transform: scale(1.05, 0.95);
}

to {
  transform: scale(1, 1);
}
}

.hidden-xs-up {
display: none!important;
}
.cssbuttons-io-button {
  background-image: url(../../assets/BGs/BG\ Plain.png);
  color: var(--color-white);
  font-family: inherit;
  padding: 0.35em;
  padding-left: 1.2em;
  font-weight: 700;
  font-size: 20px;
  font-family: var(--font-family-montserrat);
  border-radius: 5px;
  border: none;
  box-shadow:0px 0px 10px 0px rgb(255, 255, 255);
  display: flex;
  align-items: center;
  width: 250px;
  
  overflow: hidden;
  position: relative;
  height: 2.8em;
  padding-right: 3.3em;
}
form{
  margin: 0px;
}
.cssbuttons-io-button .icon {
  background: var(--color-white);
  margin-left: 1em;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.2em;
  width: 2.2em;
  border-radius: 10px;
  box-shadow: 0.1em 0.1em 0.6em 0.2em var(--color-iobutton);
  right: 0.3em;
  transition: all 0.3s;
}

.cssbuttons-io-button:hover .icon {
  width: calc(100% - 0.6em);
}

.cssbuttons-io-button .icon svg {
  width: 1.1em;
  transition: transform 0.3s;
  color: black;
}

.cssbuttons-io-button:hover .icon svg {
  transform: translateX(0.1em);
}

.cssbuttons-io-button:active .icon {
  transform: scale(0.95);
}
