/* SeatDialog.css */
.seat-dialog {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.775);
    backdrop-filter: blur(10px);
    border: 1px solid #ccc;
    padding: 10px;
    /* width: 200px; */
    display: flex;
    width: 150px;
    /* height: 200px; */
    
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 5px;
    border-radius: 15px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }


.seat-dialog p{
    font-family: var(--font-family-montserrat);
    width: 100%;
    text-align: center;
}


.seatDialog-pfp{
  height: 75px;
  width: 75px;
  border-radius: 100%;
}