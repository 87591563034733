.home-hero-logo{
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -999999;
}

.home-ellipse-img{
    width: 350px;
    height: auto;
    backdrop-filter: blur(8px);
    mix-blend-mode:darken;
    border-radius: 100%;
    box-shadow: 0px 0px 20px 0px rgb(255, 255, 255);
    z-index: -1;
}
.home-halolegion-logo{
    width: 400px;
    height: auto;
    position: absolute;
    
}
.home-hero-text{
    text-align: center;
    position: absolute;
    align-items: center;
    align-self: center;
    z-index: -2;
    overflow: hidden;
    /* display: flex; */
}
.home-hero-halocon-text{
    font-size: 80px;
    white-space:pre;
    letter-spacing: 150px;
    padding-left: 160px;
    text-align: center;
    mix-blend-mode: exclusion;
    
}
.home-hero-year-text{
    font-size: 60px;
}

.home-hero-halocon-text ,.home-hero-year-text{
    font-weight: 600;
    font-family: var(--font-family-montserrat);
}
.home-core{
    display: flex;
    flex-direction: column;
    justify-content: center;
        gap: 1rem;
    align-items: center;
    
}
.home-info-text{
    text-align: center;
    font-size: 25px;
    
}
.home-bottom{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.home-info-text-container{
    display: flex;
    padding: 0px 1rem;
    
}
.home-main{
    display: flex;
    flex-direction: column;
    height: 100vh;
    align-items: center;
    justify-content: center;
    padding-bottom:25px ;
    padding-top: 20px;
    
    
}
.home-button-container{
    display: flex;
    padding: 15px;
    gap: 180px;
    
}

.home-button{
    border-radius: 5px;
}
.home-cssbuttons-io-button {
    
    background-image: url(../../assets/BGs/BG\ Plain.png);
    backdrop-filter: blur(15px);
    color: var(--color-white);
    box-shadow: 0.1em 0.1em 0.6em 0.5em var(--color-iobutton);
    font-family: inherit;
    padding: 0.35em;
    margin-top: 30px;
    padding-left: 1.2em;
    font-weight: 700;
    font-size: 20px;
    font-family: var(--font-family-montserrat);
    border-radius: 16px;
    border: none;
    
    display: flex;
    align-items: center;
    
    overflow: hidden;
    position: relative;
    height: 3.2em;
    width: 13em;
    
    padding-right: 3.3em;
}

.home-cssbuttons-io-button .home-icon {
    background: var(--color-white);
    margin-left: 1em;   
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.2em;
    width: 2.2em;
    border-radius: 5px;
    box-shadow: 0.1em 0.1em 0.6em 0.1em var(--color-iobutton);
    right: 0.3em;
    transition: all 0.3s;
  }
.home-icon {
    background: var(--color-white);
    margin-left: 1em;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.2em;
    width: 2.2em;
    border-radius: 5px;
    box-shadow: 0.1em 0.1em 0.6em 0.1em var(--color-iobutton);
    right: 0.3em;
    transition: all 0.3s;
  }
.home-cssbuttons-io-button:hover .home-icon {
    width: calc(100% - 0.6em);
}

.home-cssbuttons-io-button .home-icon svg {
width: 1.2em;
transition: transform 0.3s;
color: #000000;
}

.home-cssbuttons-io-button:hover .home-icon svg {
transform: translateX(0.1em);
}

.home-cssbuttons-io-button:active .home-icon {
transform: scale(0.95);
}



/* @media (max-width: 768px) */
@media (max-width: 899px){
    .home-cssbuttons-io-button{
        height:2.5em;
    }
    .home-icon {
        height: 1.5em;
        width: 1.8em;

    }
    .home-hero-halocon-text {
      /* font-size: 50px;  */
      /* font-size: 22px; */
      font-size: 4vh;
      /* white-space: wrap; */
      /* letter-spacing: 20px;  */
      letter-spacing: 5vw; 
      word-spacing: 60px;
      padding-left:20px;
      text-align: center;

    }
    /* .home-hero-halocon-text{
        font-size: 80px;
        white-space:pre;
        letter-spacing: 150px;
        padding-left: 160px;
        text-align: center;
        mix-blend-mode: exclusion;
        
} */
.home-hero-year-text {
      font-size: 40px; 
    }
  
    .home-ellipse-img {
      /* width: 300px;  */
      /* width: 170px;  */
      /* width: 140px; */
      /* width: 10vw; */
 width: 25vh;
    }
  
    .home-halolegion-logo {
      /* width: 350px;  */
      /* width: 220px;  */
      /* width:180px ; */
      width: 32vh;
    }
    .home-main{
        padding-top: 10vh;
    }
    .home-info-text {
        font-size: 14px; 
        margin: 10px; 
        margin-top: 60px; 
    }
    
    .home-button-container {
        flex-direction: column; 
        gap: 10px; 
    }
    
}
@media (max-width: 450px){
    .home-hero-halocon-text {
      /* font-size: 50px;  */
      /* font-size: 22px; */
      font-size: 3vh;
      /* white-space: wrap; */
      /* letter-spacing: 20px;  */
      letter-spacing: 4vw; 
      word-spacing: 63px;
      padding-left:16px;
      text-align: center;

    }
    /* .home-hero-halocon-text{
        font-size: 80px;
        white-space:pre;
        letter-spacing: 150px;
        padding-left: 160px;
        text-align: center;
        mix-blend-mode: exclusion;
        
} */
.home-hero-year-text {
      font-size: 40px; 
    }
  
    .home-ellipse-img {
      /* width: 300px;  */
      /* width: 170px;  */
      /* width: 140px; */
      /* width: 10vw; */
 width: 22vh;
    }
  
    .home-halolegion-logo {
      /* width: 350px;  */
      /* width: 220px;  */
      /* width:180px ; */
      width: 28 vh;
    }
    .home-main{
        padding-top: 10vh;
    }
    .home-button-container{
       
        margin-top: 60vh;
    }
    .home-info-text {
        font-size: 14px; 
        margin: 10px; 
        margin-top: 60px; 
    }
    
    .home-button-container {
        flex-direction: column; 
        gap: 10px; 
        margin-top: 80px; 
    }
    
}
@media (max-width: 355px){
    .home-hero-halocon-text {
      /* font-size: 50px;  */
      /* font-size: 22px; */
      font-size: 2.5vh;
      /* white-space: wrap; */
      /* letter-spacing: 20px;  */
      letter-spacing: 4vw; 
      word-spacing: 19vw;
      padding-left:14px;
      text-align: center;

    }
    /* .home-hero-halocon-text{
        font-size: 80px;
        white-space:pre;
        letter-spacing: 150px;
        padding-left: 160px;
        text-align: center;
        mix-blend-mode: exclusion;
        
} */
.home-hero-year-text {
      font-size: 40px; 
    }
  
    .home-ellipse-img {
      /* width: 300px;  */
      /* width: 170px;  */
      /* width: 140px; */
      /* width: 10vw; */
 width: 22vh;
    }
    .home-cssbuttons-io-button{
height: 2.5em;
width: 12em;
    }
    .home-halolegion-logo {
      /* width: 350px;  */
      /* width: 220px;  */
      /* width:180px ; */
      width: 28 vh;
    }
    .home-main{
        padding-top: 10vh;
    }
    .home-button-container{
       
        margin-top: 60vh;
    }
    .home-info-text {
        font-size: 14px; 
        margin: 10px; 
        margin-top: 60px; 
    }
    
    .home-button-container {
        flex-direction: column; 
        gap: 10px; 
        margin-top: 80px; 
    }
    
}
    @media (min-width:900px) and (max-width:1300px) {
        
        .home-hero-halocon-text {
            /* font-size: 50px;  */
            font-size: 8vh;
            /* white-space: wrap; */
            /* letter-spacing: 20px;  */
            letter-spacing: 6vw; 
            word-spacing: 8vw;
            padding-left: 70px;
            text-align: center;
      
          }
          .home-hero-year-text {
            font-size: 40px; 
          }
          .home-button-container{
       
            margin-top: 10vh;
        }
        
          .home-ellipse-img {
               width: 44vh;
          }
        
          .home-halolegion-logo {
       width: 55vh;
          }
          .home-info-text {
            font-size: 14px; 
            margin: 10px; 
            margin-top: 100px; 
        }
        .home-main{

            margin-top: 12vh;
        }
    }
  
