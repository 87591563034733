@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");

/* Your existing CSS code goes here */
.seats-container-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  
  padding: 0.5rem;
  box-shadow: 0px 0px 20px 0px rgb(255, 255, 255);
  border-radius: 25px;
  backdrop-filter: blur(15px);
  overflow-y: visible; 
  
}

.team-selection-title{
  align-items: center;
  text-align: center;
  margin: 0;
}
.team-selection-block{
  border: 2px solid grey;
  width: 18em;
  padding: 0.5rem 0;
  height: 100%;
  border-radius: 20px;
  text-align: center;
  overflow-y: scroll;
  backdrop-filter: blur(8px);
}

.team-selection-players-team-1{
  border: 2px solid grey;
  border-radius: 20px;
  margin: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  backdrop-filter: blur(18px);
}

/* .team-selection-players-team-1:hover {
  border: 4px solid red;
  cursor: crosshair;
} */
/* .team-selection-players-team-1.selected {
  border: 4px solid red;
  cursor: crosshair;
} */

.team-selection-players-team-1.selected {
  border: 4px solid red; /* Red border when selected */
  cursor: crosshair;
}


.team-selection-players-name{
  text-align: start;
  /* line-height: 2em; */
  padding-left: 10px;
}
.team-selector-main-core{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  
}
.seats-icon-exit{
  width: 50px;
}
.seats-icon-entry{
  width: 50px;
}
.seats-container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.seats-bottom{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.seats-row{
  display: flex;
}
.seats-main{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  gap: 0.5rem;
  width: 100%;
  overflow-y: hidden;
  padding: 1rem;
}
.seats-core-container{
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
#bottom-right{
  width: 235px;
  align-items: flex-start;
}
#bottom-left{
  width: 235px;
  align-items: flex-end;
}
.seats-core{
  width: 100%;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  
}
.seats-core-legend{
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
}
.seat {
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: var(--font-family-montserrat);
  font-weight: 600;
  height: 25px;
  width: 25px;
  font-size: 16px; /* Adjust the font size */
  border-radius: 3px;
  border-color: black;
}
.seat-control{
  width: 40px;
}
.seats-container-core{
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: white;
  padding: 0rem;
  width: 100%;
  height: 100%;
  
}

.seats-rows-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}

.seats-rows-container-control{
  display: flex;
  justify-content: center;
  gap: 50px;

}
.seats-top-center-control{
  display: flex;
  height: 100%;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  width: 100%;

}
.seats-rows-index-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  
}
.seats-top-control {
  display: flex;
  gap: 1rem;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.seats-top {
  display: flex;
  height: 100%;
  width: 100%;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
}
.seat-row-control-left{
  font-size: 20px;
  text-align: center;
  display: flex;
  gap: 5px;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
}
.seat-row-control-right{
  font-size: 20px;
  text-align: center;
  display: flex;
  gap: 5px;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
}
.seat-rows-container-control{
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  
  
}
.seats-top-container{
  width: 100%;
  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  
}
.seat-rows-container{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  
  
}

.seats-dropdown-text{
  font-size: 15px;
}
.seats-cssbuttons-io-button {
  background-image: url(../../assets/BGs/BG\ Plain.png);
  color: var(--color-white);
  font-family: inherit;
  padding: 0.35em;
  padding-left: 1.2em;
  font-weight: 700;
  font-size: 16px;
  font-family: var(--font-family-montserrat);
  border-radius: 25px;
  border: none;
  box-shadow:0px 0px 10px 0px rgb(255, 255, 255);
  display: flex;
  align-items: center;
  
  overflow: hidden;
  position: relative;
  height: 1em;
  padding-right: 3.3em;
}

.seats-cssbuttons-io-button .seats-icon {
  background: var(--color-white);
  margin-left: 1em;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.2em;
  width: 2.2em;
  border-radius: 25px;
  box-shadow: 0.1em 0.1em 0.6em 0.2em var(--color-iobutton);
  right: 0.3em;
  transition: all 0.3s;
}

.seats-cssbuttons-io-button:hover .seats-icon {
  width: calc(100% - 0.6em);
}

.seats-cssbuttons-io-button .seats-icon svg {
  width: 1.1em;
  transition: transform 0.3s;
  color: black;
}

.seats-cssbuttons-io-button:hover .seats-icon svg {
  transform: translateX(0.1em);
}

.seats-cssbuttons-io-button:active .seats-icon {
  transform: scale(0.95);
}
.seat-row{
  font-size: 20px;
  text-align: center;
  /* width: 1300px; */
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
}

.seat-row-index{
  width: 25px;
  text-align: center;
  font-family: var(--font-family-montserrat);
}
.seats-dropdown{
  display: flex;
  font-family: var(--font-family-montserrat);
  font-size: 25px;
  gap: 10px;
  align-items: center;
  justify-content: center;
}
.seat-Booked {
  background-color: gray;
}


.seats-showcase-seat {
  background-color: white;
  height: 20px; /* Adjust the height */
  width: 17px; /* Adjust the width */
  border-top-left-radius: 5px; /* Adjust border radius */
  border-top-right-radius: 5px; /* Adjust border radius */
}
.seat-selected {
  background-color: green;
}

.seats-showcase-seat.selected {
  background-color: green;
}

.seats-showcase-seat.sold {
  background-color: gray;
}







.showcase {
  margin: 0;
  color: white;
  font-family: var(--font-family-montserrat);
  font-weight: 600;
  list-style-type: none;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0;
  justify-content: space-between;
}

.showcase li {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.showcase li small {
  margin-left: 2px;
  font-size: 15px;
}
.seats-icons-container{
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0rem 0rem 0rem 3rem;
}
.seat:not(.seat-Booked):hover {
  cursor: pointer;
  transform: scale(1.2);
  background-color: green;
}
.screen-container{
  perspective: 1000px;
  width: 100%;
  height: 100%;
  
  padding-left: 10rem;
  display: flex;
  /* padding: 0rem 5rem; */
  justify-content: center;
  gap: 4rem;
  align-items: center;
}

.screen {
  background-color: #fff;
  height: 100%;
  width: 80%;
  transform: rotateX(45deg);
  box-shadow: 0 3px 10px rgba(255, 255, 255, 0.7);
}
.control {
  background-color: #fff;
  color: black;
  display: flex;
  flex-direction: column;
  font-family: var(--font-family-montserrat);
  font-weight: 600;
  height: 100%;
  width: 100%;
  text-align: center;
  justify-content: center;
  box-shadow: 0 3px 10px rgba(255, 255, 255, 0.7);
}



/* Responsive styles */


