.menu-main{
    display: flex;
    justify-content: center;
    text-align: center;
    background-color: var(--color-footer);
    border-radius: 25px;
    margin: 10px;
}
.menu-core{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-family: var(--font-family-montserrat);
    font-weight: 600;

}
