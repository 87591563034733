*{box-sizing: border-box;
    scroll-behavior:smooth;
    }
    
    body{
        background-image: url(./assets/BGs/BG\ Plain.png); 
        background-repeat: no-repeat;
        /* object-fit: contain; */
        width: 100vw;
        height: 100vh;
        align-items: center;
        
        /* background-size: contain; */
        background-position: center;
    }
    
    
    a {color: unset;
    text-decoration: none;}
    
    .gradient__bg {
    
        background:-moz-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);
    
        background:-webkit-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);
    
        background:-o-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);
    
        background:-ms-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);
    
        background:radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);
    
    }
    
    
    
    
    
    
    
    
    
    