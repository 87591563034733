.verify_team-selector-main-core{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    /* background-color: green; */
    gap: 1rem;
  }
  .verify-main{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 90vh;
    /* background-color: blue; */
    
  }
  
  .verify-top-container{
    display: flex;
    /* background-color: green; */
    width: 100%;
    padding: 1rem;
    justify-content: space-evenly;
    align-items: center;
  }
.verify_team-selection-players-block{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  width: 100%;
  overflow-y: scroll;
  /* background-color: red; */
  /* height: 100%; */
  
}
.Team_profile{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  /* background-color: red; */

  width: 100%;
  padding: 1rem;
  
}
.verify_team-selection-block{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  gap: 1rem;
  border: 2px solid grey;
  width: 100%;
  max-width: 500px;
  height: 100%;
  border-radius: 20px;
  text-align: center;
  overflow: hidden;
  backdrop-filter: blur(8px);
}
.verify_team_top-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.verify_team_player-pfp{
  width: 100px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px rgb(255, 255, 255);

}
.verify_player_container{
  width: 100%;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background-color: green; */
}
.verify_team_selection-player-name-container{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.verify_team_selection-player-name-section{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
  .verify_team-selection-players-team-1{
    display: flex;
    /* flex-wrap: wrap; */
    /* flex-direction: column; */
    justify-content: space-around;
    gap: 1rem;
    align-items: center;
    border: 2px solid grey;
    border-radius: 20px;
    width: 100%;
    padding: 1rem;
    backdrop-filter: blur(18px);
  }
  
  .verify_team-selection-players-team-1:hover {
    border: 4px solid red;
    cursor: crosshair;
  }
  .verify_team-selection-players-team-1:active {
    border: 4px solid red;
    cursor: crosshair;
  }
  .verify_team-selection-players-name{
    text-align: right;
    color: skyblue;
    font-weight: 600;
    font-family: var(--font-family-montserrat);
  }
  .verify_team-selection-players-name2{
    text-align: left;
    font-weight: 600;
    font-family: var(--font-family-montserrat);
  }

  .verify_seats-container-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 1rem;
    box-shadow: 0px 0px 20px 0px rgb(255, 255, 255);
    border-radius: 25px;
    backdrop-filter: blur(15px);
    overflow: hidden;
    /*Temperary..................................*/
  }

  .verify_seats-container-core{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border: white;
    padding: 1rem;
    width: 100%;
    height: 100%;
  }

  .verify_seats-rows-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    width: 1500px;
  
  }
  .verify_screen-container{
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
  }

  .team_profile{
    margin-top: 4em;
    margin-bottom: 4em;
  }
.team_profile_img1,.team_profile_img2{
  height: 250px;
  border-radius: 15px;
  box-shadow: 0px 0px 15px 5px rgb(253, 251, 251);
}

.verify_team_details{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 0.5rem;
    width: 100%;
    
    border-radius: 15px;
    box-shadow: 0px 0px 15px 5px rgb(253, 251, 251);
   
}
.verify_team-selection-players-name-container{
  display: flex;
}
.verify_team_details-flex{
  display: flex;
  justify-content: center;
  align-items: left;
  width: 100%;
  gap: 1rem;
  
}
.verify_player_details2{
    font-size: 20px;
    letter-spacing: 4px;
    
    width: 50%;
    font-weight: 600;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}
.verify_player_details{
  font-size: 20px;
  width: 50%;
  letter-spacing: 4px;
  color: skyblue;
  text-align: right;
  font-weight: 600;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}
.verify_button-block{
    display: flex;
    margin-top: 2em;
    gap: 2em;
    
}
.verify_button{
    width: 20em;
    height: 4em;
    background-color: transparent;
    color: white;
    backdrop-filter: blur(10);
    /* box-shadow: ; */
}
.verify_teams-h1{
  margin: 0;
}










.verify_player_details{
  font-family: var(--font-family-montserrat);
}






























/* From uiverse.io by @satyamchaudharydev */
/* this button is inspired by -- whatsapp input */
/* == type to see fully interactive and click the close buttom to remove the text  == */

.verify_search-form {
  --input-bg: #FFf;
  --padding: 1.5em;
  --rotate: 80deg;
  --gap: 2em;
  --icon-change-color: #15A986;
  --height: 40px;
  width: 100%;
  padding-inline-end: 1em;
  background: var(--input-bg);
  position: relative;
  border-radius: 4px;
}

.verify_search-form label {
  display: flex;
  align-items: center;
  width: 100%;
  height: var(--height);
}

.verify_search-form input {
  width: 100%;
  padding-inline-start: calc(var(--padding) + var(--gap));
  outline: none;
  background: none;
  border: 0;
}
/* style for both icons -- search,close */
.verify_search-form svg {
  /* display: block; */
  color: #111;
  transition: 0.3s cubic-bezier(.4,0,.2,1);
  position: absolute;
  height: 15px;
}
/* search icon */
.verify_search_icon {
  position: absolute;
  left: var(--padding);
  transition: 0.3s cubic-bezier(.4,0,.2,1);
  display: flex;
  justify-content: center;
  align-items: center;
}
/* arrow-icon*/
.verify_search-swap-off {
  transform: rotate(-80deg);
  opacity: 0;
  visibility: hidden;
}
/* close button */
.verify_search-close-btn {
  /* removing default bg of button */
  background: none;
  border: none;
  right: calc(var(--padding) - var(--gap));
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #111;
  padding: 0.1em;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition: 0.3s;
  opacity: 0;
  transform: scale(0);
  visibility: hidden;
}

.verify_search-form input:focus ~ .verify_search_icon {
  transform: rotate(var(--rotate)) scale(1.3);
}

.verify_search-form input:focus ~ .verify_search_icon .swap-off {
  opacity: 1;
  transform: rotate(-80deg);
  visibility: visible;
  color: var(--icon-change-color);
}

.verify_search-form input:focus ~ .verify_search_icon .swap-on {
  opacity: 0;
  visibility: visible;
}

.verify_search-form input:valid ~ .verify_search_icon {
  transform: scale(1.3) rotate(var(--rotate))
}

.verify_search-form input:valid ~ .verify_search_icon .swap-off {
  opacity: 1;
  visibility: visible;
  color: var(--icon-change-color);
}

.verify_search-form input:valid ~ .verify_search_icon .swap-on {
  opacity: 0;
  visibility: visible;
}

.verify_search-form input:valid ~ .close-btn {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: 0s;
}
































.animated-button {
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 16px 36px;
  border: 4px solid;
  border-color: transparent;
  font-size: 16px;
  background-color: inherit;
  border-radius: 100px;
  font-weight: 600;
  color: rgb(255, 255, 255);
  box-shadow: 0 0 0 2px rgb(255, 255, 255);
  cursor: pointer;
  overflow: hidden;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.animated-button svg {
  position: absolute;
  width: 24px;
  fill: rgb(255, 255, 255);
  z-index: 9;
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.animated-button .arr-1 {
  right: 16px;
}

.animated-button .arr-2 {
  left: -25%;
}

.animated-button .circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  opacity: 0;
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.animated-button .text {
  position: relative;
  z-index: 1;
  transform: translateX(-12px);
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.animated-button:hover {
  box-shadow: 0 0 0 12px transparent;
  color: #212121;
  border-radius: 12px;
}

.animated-button:hover .arr-1 {
  right: -25%;
}

.animated-button:hover .arr-2 {
  left: 16px;
}

.animated-button:hover .text {
  transform: translateX(12px);
}

.animated-button:hover svg {
  fill: #212121;
}

.animated-button:active {
  scale: 0.95;
  box-shadow: 0 0 0 4px rgb(255, 255, 255);
}

.animated-button:hover .circle {
  width: 220px;
  height: 220px;
  opacity: 1;
}






































































.verify-loaderverification-container {
  position: absolute;
  top: 45%;
  left: 48%;
}

.verify-loaderverification-square {
  width: 8px;
  height: 30px;
  background: rgb(71, 195, 248);
  border-radius: 10px;
  display: block;
  /*margin:10px;*/
  -webkit-animation: verify-loaderverification-turn 2.5s ease infinite;
  animation: verify-loaderverification-turn 2.5s ease infinite;
  box-shadow: rgb(71, 195, 248) 0px 1px 15px 0px;
}

.verify-loaderverification-top {
  position: absolute;
  left: 40%;
  top: 50%;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.verify-loaderverification-bottom {
  position: absolute;
  left: 40%;
  top: 50%;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.verify-loaderverification-left {
  position: absolute;
  left: 40%;
  top: 50%;
}

.verify-loaderverification-right {
  position: absolute;
  left: 40%;
  top: 50%;
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

@-webkit-keyframes verify-loaderverification-turn {
  0% {
    transform: translateX(0) translateY(0) rotate(0);
  }

  50% {
    transform: translateX(400%) translateY(100%) rotate(90deg);
  }

  100% {
    transform: translateX(0) translateY(0) rotate(0);
  }
}

@keyframes verify-loaderverification-turn {
  0% {
    transform: translateX(0) translateY(0) rotate(0);
  }

  70% {
    transform: translateX(400%) translateY(100%) rotate(90deg);
  }

  100% {
    transform: translateX(0) translateY(0) rotate(0);
  }
}




































.verify-image-zoom-close-button {
  position: relative;
  width: 4em;
  height: 4em;
  border: none;
  background: rgba(180, 83, 107, 0.11);
  border-radius: 5px;
  transition: background 0.5s;
}

.verify-image-zoom-close-X {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2em;
  height: 1.5px;
  background-color: rgb(255, 255, 255);
  transform: translateX(-50%) rotate(45deg);
}

.verify-image-zoom-close-Y {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2em;
  height: 1.5px;
  background-color: #fff;
  transform: translateX(-50%) rotate(-45deg);
}

.verify-image-zoom-close-close {
  position: absolute;
  display: flex;
  padding: 0.8rem 1.5rem;
  align-items: center;
  justify-content: center;
  transform: translateX(-50%);
  top: -70%;
  left: 50%;
  width: 3em;
  height: 1.7em;
  font-size: 12px;
  background-color: rgb(19, 22, 24);
  color: rgb(187, 229, 236);
  border: none;
  border-radius: 3px;
  pointer-events: none;
  opacity: 0;
}

.verify-image-zoom-close-button:hover {
  background-color: rgb(211, 21, 21);
}

.verify-image-zoom-close-button:active {
  background-color: rgb(130, 0, 0);
}

.verify-image-zoom-close-button:hover > .verify-image-zoom-close-close {
  animation: close 0.2s forwards 0.25s;
}

@keyframes close {
  100% {
    opacity: 1;
  }
}
















































.verify-image-zoom-main{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  justify-content: center;
  height: 100%;
  backdrop-filter: blur(15px);
  position: absolute;
  
}
.verify-image-container-top{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 0 3rem;
}
.verify-image-zoom-container{
  height: 80%;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px rgb(255, 255, 255);
}

.team_profile_img-zoom{
  height: 100%;
  border-radius: 10px;
}








































/* Hide the default checkbox */
.verify_button-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.verify_button-container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 20px;
  user-select: none;
}

/* Create a custom checkbox */
.verify_button-checkmark {
  position: relative;
  top: 0;
  left: 0;
  height: 2.3em;
  width: 2.3em;
  background-color: #ccc;
  border-radius: 50%;
  transition: .4s;
}

.verify_button-checkmark:hover {
  box-shadow: inset 17px 17px 16px #b3b3b3,
            inset -17px -17px 16px #ffffff;
}

/* When the checkbox is checked, add a blue background */
.verify_button-container input:checked ~ .verify_button-checkmark {
  box-shadow: none;
  background-color: limegreen;
  transform: rotateX(360deg);
}

.verify_button-container input:checked ~ .verify_button-checkmark:hover {
  box-shadow: 3px 3px 3px rgba(0,0,0,0.2);
}

/* Create the checkmark/indicator (hidden when not checked) */
.verify_button-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.verify_button-container input:checked ~ .verify_button-checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.verify_button-container .verify_button-checkmark:after {
  left: 0.96em;
  top: 0.7em;
  width: 0.25em;
  height: 0.5em;
  border: solid white;
  border-width: 0 0.15em 0.15em 0;
  box-shadow: 0.1em 0.1em 0em 0 rgba(0,0,0,0.3);
  transform: rotate(45deg);
}




.barcode-sort{
  display: flex;
  justify-content: flex-end;
  width: 100%;
  
}

.barcode-sort-icon{
  height: 25px;
}